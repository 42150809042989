<template>
  <div v-if="report">
    <action-header :actions-model="headerModel" :page="page" />

    <form-builder :schema="schema" />

    <div
      v-if="isLoading"
      class="text-primary col-12 row fit justify-center items-center"
      style="min-height: 300px"
    >
      <q-spinner color="light-blue-9" size="3rem" />
    </div>

    <sticky-bottom-header
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      :disabled="!!$route.params.id"
      @back="handleDiscard"
      @save="save"
    />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ActionHeader from '../../components/action-header/ActionHeader.vue'

export default {
  name: 'Report',
  components: {
    ActionHeader
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      isLoading: false,
      isPeriod: true,
    }
  },
  computed: {
    ...mapGetters([
      'report',
      'isAdministrator',
      'isSupervisior',
      'appOptions'
    ]),
    page () {
      return {
        id: this.report && this.report.id,
        name: this.report && this.report.id
          ? this.$t('Report')
          : this.$t('New report')
      }
    },
    schema () {
      const groups = this.loadFields()
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                if: !this.$route.params.type,
                type: 'multiselect',
                label: this.$t('Report type'),
                wrapperStyleClasses: this.isAdministrator || this.isSupervisior
                  ? 'col-12 col-md-6 q-pa-xs'
                  : 'col-12 q-pa-xs',
                value: this.report._embedded.reportType,
                field: 'reportType',
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [{ type: 'eq', field: 'state', value: 'active' }]
                  }

                  return this.$service.reportType.getAll(query)
                },
                onChange: (reportType) => {
                  this.updateReportEmbedded({ reportType })
                  this.updateReport({ handler: reportType.extId })
                  this.handleChange()
                }
              },
              {
                if: this.isAdministrator || this.isSupervisior,
                type: 'multiselect',
                label: this.$t('Owner'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.report._embedded.owner,
                field: 'owner',
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: (owner) => {
                  this.updateReportEmbedded({ owner })
                  this.handleChange()
                }
              },
              // Hide field for now
              {
                if: false,
                type: 'input',
                inputType: 'text',
                value: this.report.handler,
                label: this.$t('handler'),
                wrapperStyleClasses: this.isAdministrator || this.isSupervisior
                  ? 'col-12 q-pa-xs'
                  : 'col-12 col-md-6 q-pa-xs',
                onChange: (value) => {
                  this.report.handler = value
                  this.handleChange()
                }
              },
              {
                if: this.isPeriod,
                type: 'date',
                value: this.report.beginningPeriod,
                label: this.$t('Start day'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: (value) => {
                  this.report.beginningPeriod = value
                  this.handleChange()
                }
              },
              {
                if: this.isPeriod,
                type: 'date',
                value: this.report.endPeriod,
                label: this.$t('End day'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: (value) => {
                  this.report.endPeriod = value
                  this.handleChange()
                }
              },
            //   {
            // type: 'tag',
            // label: this.$t('Status'),
            // wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
            // value: this.model['state==i'],
            // field: 'state==i',
            // options: this.values['states==i'],
            // customListItem: (row) => {
            //   if (row && typeof row === 'object') {
            //     return row.name || row.title
            //   }

            //   return row
            // },
            // onRemove: (val) => {
            //   this.model['state==i'] = this.model['state==i'].filter(x => x !== val)
            // },
            // onAdd: (value) => {
            //   if (Array.isArray(value)) {
            //     return
            //   }

            //   let isExist = false

            //   this.model['state==i'] = this.model['state==i'].filter(val => {
            //     if (val === value.code) {
            //       isExist = true
            //       return false
            //     }

            //     return true
            //   })

            //   if (isExist) {
            //     return
            //   }

            //   this.model['state==i'] = [...this.model['state==i'], value.code]
            // }
            //   },
            ]
          },
          ...groups
        ]
      }
    },
    headerModel () {
      return [
        {
          section: 'BackAction',
          className: 'col-sm-1 q-pr-md hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col-sm-3 mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              className: 'text-white',
              value: this.report.id ? this.$t('Report ID: ') + this.report.id : this.$t('New Report')
            }
          ]
        },
        {
          section: 'Actions',
          className: 'col-sm-4 row text-center row justify-center',
          options: []
        }
      ]
    }
  },
  mounted () {
    
    if (!this.$route.params.id && !this.report) {
      this.setNewReport()
      if (this.$route.params.type) {
        this.isLoading = true
        this.$service.reportType.get(this.$route.params.type)
          .then(reportType => {
            if(!reportType.hasPeriod || reportType.hasPeriod === null) {

              this.isPeriod = false
              this.ifDaily()
            }
            this.updateReport({ handler: reportType.extId })
            this.updateReportEmbedded({ reportType })
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
    if (this.$route.params.id && !this.report) {
      this.loadReport(this.$route.params.id)
    }
  },
  unmounted () {
    this.deleteReport()
  },
  methods: {
    ...mapActions([
      'loadReport',
      'saveReport'
    ]),
    ...mapMutations([
      'setNewReport',
      'updateReport',
      'updateReportEmbedded',
      'deleteReport'
    ]),
    loadFields () {
      const settings = (((this.report || {})._embedded || {}).reportType || {}).settings || {}

      if (Array.isArray(settings)) {
        return settings.map(group => {
          return {
            styleClasses: 'row',
            fields: (group.attributes || []).reduce((acc, field) => {
              const newField = this.$utils.field.create(this.report.settings, { ...field, name: `${group.name}.${field.name}`})

              if (!newField) {
                return acc
              }

              newField.watch = () => {
                this.hasChange = true
              }

              acc.push(newField)

              return acc
            }, [])
          }
        })
      }

      return Object.keys(settings).reduce((acc, key) => {
        acc.push({
          styleClasses: 'row',
          fields: this.report._embedded.reportType.settings[key].reduce((acc, field) => {
            const newField = this.$utils.field.create(this.report.settings, field)

            if (!newField) {
              return acc
            }

            newField.watch = () => {
              this.hasChange = true
            }

            acc.push(newField)

            return acc
          }, [])
        })

        return acc
      }, [])
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    handleChange () {
      this.hasChange = true
    },
    ifDaily () {
      let date = this.$moment(new Date()).format(this.appOptions.formats.date)
      this.report.beginningPeriod = date
      this.report.endPeriod = date
      this.handleChange()
    },
    save () {
      this.isSaveLoading = true
      this.saveReport()
        .then(() => {
          this.hasChange = false
          this.handleBack()
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    }
  }
}
</script>
